import config from './config.json';
// import utilities from './utilities.js';

class Theme {

    constructor() {
        
        this.config = config;

        this.init();
    }


    // Class initialisation
    init() {
        const thisClass = this;
        const thisConfig = thisClass.config;

        if (thisConfig.headerSticky.activated) thisClass.headerSticky();
        if (thisConfig.panelOverlay.activated) thisClass.panelOverlay();
        if (thisConfig.preloading.activated) thisClass.preloading();
        if (thisConfig.scrollTop.activated) thisClass.scrollTop();
        if (thisConfig.seePassword.activated) thisClass.seePassword();
        if (thisConfig.wow.activated) thisClass.wow();
    }


    // Header sticky
    headerSticky() {
        const thisClass = this;
        const globalConfig = thisClass.config._global;
        const headerStickyConfig = thisClass.config.headerSticky;
        // >>>
        const header = document.querySelector('.header');
        const bodyWrapper = globalConfig.bodyWrapper != "" ? document.querySelector(globalConfig.bodyWrapper) : window;

        // Check if the header has to be sticky or not 
        const checkHeaderSticky = () => {
            if (bodyWrapper.scrollTop >= headerStickyConfig.stickyAfterScrolling) {
                header.classList.add('is-sticky');
            } else {
                header.classList.remove('is-sticky');
            }
        }

        // When the page is loaded
        checkHeaderSticky();
        
        // When the user is scrolling the page
        bodyWrapper.addEventListener('scroll', () => {
            checkHeaderSticky();
        });
    }


    // Toggle side panels
    panelOverlay() {
        const panelToggles = document.querySelectorAll('[data-toggle-panel]');
        const panelCloses = document.querySelectorAll('.panel-overlay__close');
        const panels = document.querySelectorAll('.panel-overlay');
        panelToggles.forEach(panelToggle => {
            // The user wants to open a panel
            panelToggle.addEventListener('click', function() {
                const panel = document.getElementById(this.getAttribute('data-toggle-panel'));
                panel.classList.add('active');
            });
        });
        // The user want to close the panel (click outside of the panel content)
        panelCloses.forEach(panelClose => {
            panelClose.addEventListener('click', function(e) {
                this.closest('.panel-overlay').classList.remove('active');
            });
        });
        panels.forEach(panel => {
            // The user want to close the panel (click outside of the panel content)
            panel.addEventListener('click', function(e) {
                // Check if the user is clicking outside of the panel content
                if (e.target == panel) {
                    this.classList.remove('active');
                }
            });
        });
    }


    // Adding the preloading functionnality to the body
    preloading() {
        window.addEventListener('DOMContentLoaded', function() {
            document.body.classList.remove('preload');
        });
    }


    // Scroll top
    scrollTop() {
        const thisClass = this;
        const scrollConfig = thisClass.config.scrollTop;
        const globalConfig = thisClass.config._global;
        // >>>
        const scrollTopElt = document.querySelector('.scroll-top');
        const bodyWrapper = globalConfig.bodyWrapper != "" ? document.querySelector(globalConfig.bodyWrapper) : window;

        // Function to show or hide the scroll top element
        const showHideScrollTop = (distance) => {
            if (distance >= scrollConfig.showAfterScrolling) {
                scrollTopElt.classList.add('active');
            } else {
                scrollTopElt.classList.remove('active');
            }
        }

        // Function to scroll to the top of the page smoothly
        const scrollToTop = () => {
            const c = bodyWrapper.scrollTop;
            scrollConfig.requestAnimation = window.requestAnimationFrame(scrollToTop);
            bodyWrapper.scrollTo(0, c - c / scrollConfig.scrollSmooth);
        }

        // When the page is loaded
        showHideScrollTop(bodyWrapper.scrollTop);

        // When the user is scrolling the page
        bodyWrapper.addEventListener('scroll', () => {
            showHideScrollTop(bodyWrapper.scrollTop);

            if (bodyWrapper.scrollTop === 0 && scrollConfig.requestAnimation != "") {
                cancelAnimationFrame(scrollConfig.requestAnimation);
                scrollConfig.requestAnimation = "";
            }
        });

        // When the user is clicking on the scroll top element
        scrollTopElt.addEventListener('click', () => {
            scrollToTop();
        });

    }

    // Change input password into text
    seePassword() {
        document.querySelectorAll('.see-password').forEach(element => {
            const input = element.closest('.see-password-wrap').querySelector('input');
            // When the user click on the icon
            element.addEventListener('click', function() {
                // Add active class to the icon
                this.classList.toggle('active');
                // Change the type of the input
                if (input.getAttribute('type') == 'password') {
                    input.setAttribute('type', 'text');
                } else {
                    input.setAttribute('type', 'password');
                }
            });            
        });
    }


    // WOW JS
    wow() {
        new WOW({
            scrollContainer: '#wrapper',
            offset: 150,
        }).init();
    }

}

document.addEventListener('DOMContentLoaded', () => {
    new Theme();
});
